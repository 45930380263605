@import "mvp.css/mvp.css";
@import "mvp_overrides.scss";

ul.pagination li {
  list-style: none;
  display: inline-block;
  margin-right: 10px;
}

img.image {
  margin: 10px auto;
  display: block;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: 5px;
  width: 300px;
}

figure:has(img.image) {
  width: 300px;
  text-align: center;
  margin-bottom: 20px;

  img.image {
    max-width: 100%;
    margin-bottom: 0;
  }
}

.flex-centered {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
}
